import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../layouts/mainLayout';
import HeroImage from '../components/heroImage';
import PricingCard from '../components/pricingCard';
import TestimonialCard from '../components/testimonialCard';
import BeforeAfterImage from '../components/beforeAfterImage';
import ContactForm from '../components/contactForm';

import {
  Wrapper,
  CTASection,
  TrustBadgesSection,
  ServiceSection,
  ExperienceSection,
  CalloutQuoteSection,
  PricingSection,
  TestimonalSection,
  OurProcessSection,
  OtherFactorsSection,
  BeforeAfterSection,
  ContactUsSection,
} from '../styles/pages/why-choose-us';

import config from '../config';

export const query = graphql`
  query whyChooseUsPageQuery {
    hero: file(relativePath: { regex: "/why-choose-us.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cmgIcon: file(relativePath: { regex: "/trust-badges/logo-cmg.jpg/" }) {
      childImageSharp {
        fixed(width: 113, height: 52) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bondurantIcon: file(
      relativePath: { regex: "/trust-badges/logo-bondurant.png/" }
    ) {
      childImageSharp {
        fixed(width: 105, height: 55) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bcrIcon: file(relativePath: { regex: "/trust-badges/logo-bcr.png/" }) {
      childImageSharp {
        fixed(width: 113, height: 56) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    terraceViewIcon: file(
      relativePath: { regex: "/trust-badges/logo-terrace-view.png/" }
    ) {
      childImageSharp {
        fixed(width: 135, height: 86) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    longAndFosterIcon: file(
      relativePath: { regex: "/trust-badges/logo-long-and-foster.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 150, height: 113) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    foxridgeIcon: file(
      relativePath: { regex: "/trust-badges/logo-foxridge.png/" }
    ) {
      childImageSharp {
        fixed(width: 158, height: 47) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    riceRealtyIcon: file(
      relativePath: { regex: "/trust-badges/logo-rice-realty.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 113, height: 69) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sasIcon: file(relativePath: { regex: "/trust-badges/logo-sas.jpg/" }) {
      childImageSharp {
        fixed(width: 124, height: 74) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    dogImage: file(relativePath: { regex: "/dog.png/" }) {
      childImageSharp {
        fixed(width: 362, height: 355) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    calendarIcon: file(relativePath: { regex: "/calendar.png/" }) {
      childImageSharp {
        fixed(width: 200, height: 201) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    clockIcon: file(relativePath: { regex: "/clock.png/" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    certificateIcon: file(relativePath: { regex: "/licensed.png/" }) {
      childImageSharp {
        fixed(width: 200, height: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    beforeAfterOne: file(relativePath: { regex: "/cc-gallery-1.jpg/" }) {
      childImageSharp {
        fixed(width: 283, height: 159) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfterTwo: file(relativePath: { regex: "/cc-gallery-2.jpg/" }) {
      childImageSharp {
        fixed(width: 283, height: 159) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfterThree: file(relativePath: { regex: "/cc-gallery-3.jpg/" }) {
      childImageSharp {
        fixed(width: 283, height: 159) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfterFour: file(relativePath: { regex: "/cc-gallery-4.jpg/" }) {
      childImageSharp {
        fixed(width: 283, height: 159) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfterFive: file(relativePath: { regex: "/cc-gallery-5.jpg/" }) {
      childImageSharp {
        fixed(width: 283, height: 159) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const hero = data => {
  const image = <Img fluid={data.hero.childImageSharp.fluid} />;
  return <HeroImage backdrop={false} image={image} />;
};

const WhyChooseUsPage = ({ data }) => {
  return (
    <Layout hero={hero(data)}>
      <Helmet>
        <meta
          name="description"
          content="Why you should choose us for your carpet cleaning needs."
        />
        <title>Why Choose Us | Sci-Tech Carpet Cleaning</title>
      </Helmet>
      <Wrapper>
        <p>
          Dirty carpets. Pet urine. Spills and stains. You don't need to worry
          about it. We have nearly 20 years of experience fixing these problems,
          and our results speak for themselves.
        </p>
        <p>
          For most of our clients, we clean their carpets for one of two
          reasons:
        </p>
        <ul>
          <li>An annual-ish maintenance clean.</li>
          <li>
            The carpet really needs it. There could be heavy traffic patterns,
            pet stains, odors, or other issues.
          </li>
        </ul>
        <p>
          Regardless of which category your carpets fall into, we have a package
          that is appropriate for your needs.
        </p>
        <CTASection>
          <p>
            Get in touch with our sales team to discover how we can serve you
            best today.
          </p>
          <a href={config.phoneNumberHref}>Call Now: {config.phoneNumber}</a>
        </CTASection>
        <TrustBadgesSection>
          <h2>Our Services Are Trusted By These Fine Companies Among Others</h2>
          <div className="trust-badges">
            <Img
              className="trust-badge"
              fixed={data.cmgIcon.childImageSharp.fixed}
            />
            <Img
              className="trust-badge"
              fixed={data.bondurantIcon.childImageSharp.fixed}
            />
            <Img
              className="trust-badge"
              fixed={data.bcrIcon.childImageSharp.fixed}
            />
            <Img
              className="trust-badge"
              fixed={data.terraceViewIcon.childImageSharp.fixed}
            />
            <Img
              className="trust-badge"
              fixed={data.longAndFosterIcon.childImageSharp.fixed}
            />
            <Img
              className="trust-badge"
              fixed={data.foxridgeIcon.childImageSharp.fixed}
            />
            <Img
              className="trust-badge"
              fixed={data.riceRealtyIcon.childImageSharp.fixed}
            />
            <Img
              className="trust-badge"
              fixed={data.sasIcon.childImageSharp.fixed}
            />
          </div>
        </TrustBadgesSection>
        <hr />
        <ServiceSection>
          <Img
            className="service-section__dog-image"
            fixed={data.dogImage.childImageSharp.fixed}
          />
          <div className="service-section__content">
            <h2>Problems We Routinely Correct</h2>
            <ul>
              <li>
                <h3>Pet urine</h3>
                <p>
                  Pet urine is very common in homes with pets. It's important to
                  clean it as soon as possible to maximize the chances of
                  removal. We have excellent success removing most pet stains.
                </p>
              </li>
              <li>
                <h3>Food and drink spills</h3>
                <p>
                  Soda spills are no problem. We remove them routinely. Watch
                  out for food and drink spills with dyes in them. If one of
                  these occurs, <a href={config.phoneNumberHref}>call us</a>{' '}
                  ASAP.
                </p>
              </li>
              <li>
                <h3>Heavy dirt and soiling</h3>
                <p>
                  Our deluxe package is terrific when it comes to removing heavy
                  dirt and soiling. You won't believe how great your carpets
                  look.
                </p>
              </li>
              <li>
                <h3>Color stains</h3>
                <p>
                  When spills happen that have dyes in them, it can lead to a
                  serious problem. It's important to get in touch with us as
                  soon as possible. We have special spotting procedures to
                  remove these stains for you.
                </p>
              </li>
              <li>
                <h3>Odors in carpets</h3>
                <p>
                  We stock a wide selection of powerful deodorizers that are
                  perfect for nearly every situation.
                </p>
              </li>
              <li>
                <h3>And more...</h3>
              </li>
            </ul>
          </div>
        </ServiceSection>
        <hr />
        <ExperienceSection>
          <h2>We Have The Experience</h2>
          <p>
            With nearly 20 years of experience, we have been a leading provider
            of carpet cleaning services in Blacksburg, Christiansburg, Radford,
            Roanoke, Salem, and the rest of the New River Valley for some time.
          </p>
          <p>
            If you open the phone book and look for carpet cleaners, you'll
            likely find more than a dozen available. Few of these have even half
            of the experience that we have dealing with the challenges that
            cleaners face.
          </p>
          <p>Our experience has helped our ability to:</p>
          <ul>
            <li>
              Thoroughly clean carpets without causing any damage to the
              sensitive fibers.
            </li>
            <li>Remove tough stains that other cleaners leave behind.</li>
            <li>Clean carpets fully without leaving them too wet.</li>
            <li>
              Remove odors from the carpets leaving them smelling fresh and
              clean.
            </li>
            <li>
              Remove allergens from the carpets helping you to breathe easier.
            </li>
          </ul>
        </ExperienceSection>
        <CalloutQuoteSection>
          <blockquote>
            <p>
              Sci-Tech saved me thousands on carpet replacement. Who would have
              thought that purple nail polish could come out of white carpet. I
              thank you and my daughter thanks you.
            </p>
            <footer>Nancy</footer>
          </blockquote>
        </CalloutQuoteSection>
        <PricingSection>
          <h2>Our Pricing Is Extremely Competitive</h2>
          <p>
            We know that pricing is one of the most important considerations
            when selecting a service provider. Nobody wants to pay too much for
            a service. And you definitely don't want to pay too much for the
            same quality of service you can get elsewhere.
          </p>
          <p>
            At Sci-Tech Carpet Cleaning, we strive to raise the bar with our
            service. We want to offer a service above and beyond what other
            cleaners are able to offer.
          </p>
          <p>
            But we also recognize the benefits of reasonable pricing. That's why
            our services are priced to be competitive with other cleaners even
            though we believe we raise the bar with our service.
          </p>
          <p>
            So you have no need to worry that you'll be paying too much. Take a
            quick look below at our package offers. With a choice of cleaning
            package, you have more control over your budget and what you need.
          </p>
          <PricingCard highlight="Most popular option">
            <h4>Basic Package</h4>
            <hr />
            <ul>
              <li>Use of industry standard wand tool</li>
              <li>Hot water extraction (steam cleaning)</li>
              <li>Pre-treat all traffic areas</li>
              <li>Basic spotting included</li>
            </ul>
          </PricingCard>
          <PricingCard highlight="Deep cleaning option">
            <h4>Deluxe Package</h4>
            <hr />
            <ul>
              <li>Use of special rotary tool to scrub carpets</li>
              <li>Hot water extraction (steam cleaning)</li>
              <li>Pre-treat all traffic areas</li>
              <li>Basic spotting included</li>
            </ul>
          </PricingCard>
        </PricingSection>
        <CTASection>
          <p>
            Get in touch with our sales team to discover how we can serve you
            best today.
          </p>
          <a href={config.phoneNumberHref}>Call Now: {config.phoneNumber}</a>
        </CTASection>
        <TestimonalSection>
          <h2>See What Our Clients Are Saying About Us</h2>
          <div className="testimonial-section__testimonials">
            <TestimonialCard>
              <blockquote>
                <p>
                  I had very little expectation that this carpet could even look
                  halfway decent. I was getting it cleaned to help with a mold
                  issue in this old house we are renting. I had to be gone for a
                  day or so and during this time they came in to clean the
                  carpet. When I got back and opened the door, I thought I was
                  at the wrong house. It was amazing. They got things out of the
                  carpet that I thought were permanent. Amazing results!
                </p>
                <footer>Anonymous</footer>
              </blockquote>
            </TestimonialCard>
            <TestimonialCard>
              <blockquote>
                <p>
                  Very quick response to our request. Technician showed up
                  exactly on time; was very knowledgable, professional, and
                  pleasant. Work done quickly and looks great. Price very
                  reasonable - and how it was priced was clearly explained
                  before service contract agreed and at the time of payment.
                </p>
                <footer>Harris J.</footer>
              </blockquote>
            </TestimonialCard>
            <TestimonialCard>
              <blockquote>
                <p>
                  Great Service! We were worked into a busy schedule on short
                  notice and the carpets looked great. Also very well priced.
                </p>
                <footer>Jason W.</footer>
              </blockquote>
            </TestimonialCard>
            <TestimonialCard>
              <blockquote>
                <p>
                  Since I moved to northern Virginia I cannot find a carpet
                  company as good as you. Do you know of a good company near
                  Alexandria? I still own a townhouse in Christiansburg and use
                  your services every year. I appreciate the excellent results!
                  It is so hard to find a quality and honest company.
                </p>
                <footer>Melissa</footer>
              </blockquote>
            </TestimonialCard>
            <TestimonialCard>
              <blockquote>
                <p>
                  I needed a large room with wall to wall carpeting cleaned,
                  also an area rug and a set of stairs. In addition, they took
                  away a wool area rug to be cleaned at their place of business.
                </p>
                <p>
                  Very well. I was very happy with the results. I felt that the
                  fee was reasonable, though I don't remember exactly what I
                  paid at the time. The person I talked to when I made the
                  appointment, Michael, was very helpful and answered my
                  questions and concerns regarding pet odor on the wall to wall
                  carpeting. I will call them again when the carpets need
                  cleaning.
                </p>
                <footer>Anonymous</footer>
              </blockquote>
            </TestimonialCard>
            <TestimonialCard>
              <blockquote>
                <p>
                  Sci-Tech saved me thousands on carpet replacement. Who would
                  have thought that purple nail polish could come out of white
                  carpet. I thank you and my daughter thanks you.
                </p>
                <footer>Nancy</footer>
              </blockquote>
            </TestimonialCard>
          </div>
        </TestimonalSection>
        <hr />
        <OurProcessSection>
          <h2>Take A Look At Our Process</h2>
          <p>
            We use a proven multi-step process to deliver great results with
            every cleaning. Read below to discover how our cleaning process can
            make your carpets cleaner and healthier today.
          </p>
          <ul>
            <li>
              <h3>Consult with sales.</h3>
              <p>
                The first step is to get in touch with our sales team. Our sales
                team can help you decide which package is best for your needs
                and schedule your service appointment. They will also be able to
                give you an accurate price quote and information personalized
                for you.
              </p>
            </li>
            <li>
              <h3>Crew arrives on time and performs pre-inspection.</h3>
              <p>
                When we schedule service appointments, we try to be as precise
                as possible. This means that we are almost always accurate with
                our timing. Our crews almost always arrive on time. After
                arrival, our crew will perform a pre-inspection discussing with
                you any necessary details of the work order.
              </p>
            </li>
            <li>
              <h3>Pre-treat all traffic areas.</h3>
              <p>
                Part of our process is to pre-treat all traffic areas with a
                heavy-duty traffic lane cleaner. This helps to delivery amazing
                results consistently with our cleaning.
              </p>
            </li>
            <li>
              <h3>
                Thoroughly clean the carpet using the appropriate tool for the
                package selected.
              </h3>
              <p>
                Our basic package uses an industry-standard wand tool to clean
                the carpet. Our deluxe package uses a heavy-duty rotary tool to
                scrub the carpet along with the cleaning process. The rotary
                tool is most useful when traffic lanes have a significant amount
                of dirt embedded into them.
              </p>
            </li>
            <li>
              <h3>Specialty spot where needed.</h3>
              <p>
                Both of our packages come with basic specialty spotting
                included. This covers a wide variety of spots and stains. If
                they don't come out with the regular cleaning process, we use
                specialty spotters to treat and remove them.
              </p>
            </li>
            <li>
              <h3>Post-cleaning walkthrough.</h3>
              <p>
                After the work is completed, our crew will walk through the
                cleaned areas with you so you can take a look at the finished
                product. At this stage, any important details will be discussed
                with you and you can be certain that you are happy with the work
                performed.
              </p>
            </li>
          </ul>
        </OurProcessSection>
        <hr />
        <OtherFactorsSection>
          <h2>
            Consider These Other Factors When Deciding Which Carpet Cleaning
            Company To Choose
          </h2>
          <div className="inner-wrapper">
            <div className="column">
              <Img fixed={data.calendarIcon.childImageSharp.fixed} />
              <h3>Flexible Scheduling</h3>
              <p>
                We currently have nine carpet cleaning vans. This means we have
                a lot of flexibility in our scheduling opportunities. It's very
                unlikely that you'll have to wait very long to schedule an
                appointment with us.
              </p>
            </div>
            <div className="column">
              <Img fixed={data.clockIcon.childImageSharp.fixed} />
              <h3>Almost Always On Time</h3>
              <p>
                We pride ourselves on being punctual. We try to schedule as
                accurate as we can. But in the event that unforseen
                circumstances prevent us from making your appointment on time,
                we will give you a call to let you know.
              </p>
            </div>
            <div className="column" style={{ paddingTop: '25px' }}>
              <Img fixed={data.certificateIcon.childImageSharp.fixed} />
              <h3 style={{ marginTop: '46px' }}>We Are Licensed And Insured</h3>
              <p>
                With Sci-Tech Carpet Cleaning, you have no need to worry about
                the safety of your carpets and possessions. We are fully
                licensed and insured and our team members undergo background
                checks.
              </p>
            </div>
          </div>
        </OtherFactorsSection>
        <hr />
        <BeforeAfterSection>
          <h2>Check Out A Few Before / After Pictures</h2>
          <div className="before-after-section__inner-wrapper">
            <BeforeAfterImage
              thumbnail={
                <Img fixed={data.beforeAfterOne.childImageSharp.fixed} />
              }
              image={<Img fluid={data.beforeAfterOne.childImageSharp.fluid} />}
            >
              <p>
                This berber carpet was quite filthy. By pre-treating it before
                cleaning, we were able to remove nearly all of the dirt in the
                carpet.
              </p>
            </BeforeAfterImage>
            <BeforeAfterImage
              thumbnail={
                <Img fixed={data.beforeAfterTwo.childImageSharp.fixed} />
              }
              image={<Img fluid={data.beforeAfterTwo.childImageSharp.fluid} />}
            >
              <p>
                This is a different angle of the same carpet in the image
                before. This carpet was cleaned using our basic cleaning
                package.
              </p>
            </BeforeAfterImage>
            <BeforeAfterImage
              thumbnail={
                <Img fixed={data.beforeAfterThree.childImageSharp.fixed} />
              }
              image={
                <Img fluid={data.beforeAfterThree.childImageSharp.fluid} />
              }
            >
              <p>
                See the difference in this carpet? This is a thick and cream
                colored nylon carpet. It looked like new after cleaning.
              </p>
            </BeforeAfterImage>
            <BeforeAfterImage
              thumbnail={
                <Img fixed={data.beforeAfterFour.childImageSharp.fixed} />
              }
              image={<Img fluid={data.beforeAfterFour.childImageSharp.fluid} />}
            >
              <p>
                This was a large basement carpet that we cleaned. The outside
                edges of the picture are the areas we've already cleaned. Notice
                how much brighter the carpet looks.
              </p>
            </BeforeAfterImage>
            <BeforeAfterImage
              thumbnail={
                <Img fixed={data.beforeAfterFive.childImageSharp.fixed} />
              }
              image={<Img fluid={data.beforeAfterFive.childImageSharp.fluid} />}
            >
              <p>
                This is a picture of our deluxe package on display. The swirl
                marks in the carpet are from the rotary machine. The dark
                traffic areas in this carpet are being completely removed.
              </p>
            </BeforeAfterImage>
          </div>
        </BeforeAfterSection>
        <hr />
        <ContactUsSection>
          <h2>Still Have More Questions?</h2>
          <p>
            Then it's time to get in touch with our sales team. They'll be happy
            to assist you with any questions that you might have.
          </p>
          <p>
            We serve Blacksburg, Christiansburg, Radford, Roanoke, Salem, and
            the rest of the New River Valley. We would love to hear from you!
          </p>
          <ContactForm />
        </ContactUsSection>
      </Wrapper>
    </Layout>
  );
};

export default WhyChooseUsPage;
