import React from 'react';

import Styled from 'styled-components';

const PricingCard = ({ children, highlight }) => {
  return (
    <Wrapper className="pricing-card">
      {highlight && <div className="pricing-card__highlight">{highlight}</div>}
      {children}
    </Wrapper>
  );
};

const Wrapper = Styled.div`
  min-width: 15em;
  background: #fff;
  border-radius: 2px;
  padding: 2em 1em 1em 1em;
  display: inline-block;
  margin: 1rem;
  position: relative;
  width: 300px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  box-sizing: border-box;

  .pricing-card__highlight {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    color: #FFF;
    background: #2A67B6;
    text-align: center;
    line-height: 1.6;
  }

  @media screen and (max-width: 560px) {
    min-width: 0;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;

export default PricingCard;
