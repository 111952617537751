import Styled from 'styled-components';
import { baseMixin, fullWidthMixin } from '../mixins';

import CheckMark from '../../images/check-mark.png';

const Wrapper = Styled.div`
${baseMixin};
font-size: 20px;
hr {
  border: 1px solid #DDDDDD;
  margin-top: 60px;
  margin-bottom: 60px;
}
`;

const CTASection = Styled.section`
  margin-top: 60px;
  margin-bottom: 60px;
  background-color: #2A67B6;
  padding: 24px;
  text-align: center;
  color: white;
  box-sizing: border-box;

  ${fullWidthMixin};

  p {
    margin-top: 0;
  }

  a {
    color: white;
    background-color: #D04D2D;
    text-decoration: none;
    padding: 10px 16px;
    border-radius: 6px;
    margin-top: 12px;
    display: inline-block;

    &:hover {
      background-color: #CE6D2D;
      text-decoration: none;
      color: white;
    }
  }
`;

const TrustBadgesSection = Styled.section`
  ${fullWidthMixin};
  box-sizing: border-box;

  h2 {
    text-align: center;
  }

  .trust-badges {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .trust-badge {
      margin: 24px;
    }
  }
`;

const ServiceSection = Styled.section`
  ${fullWidthMixin};

  padding: 30px 30px;
  box-sizing: border-box;

  .service-section__content {
    max-width: calc(100% - 460px);
    float: right;

    h2, ul {
      float: left;
    }

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  h3 {
    margin-top: 0;
  }

  .service-section__dog-image {
    float: left;
    width: 362px;
  }

  &:after {
    clear: both;
    display: table;
    content: '';
  }

  @media screen and (max-width: 991px) {
    .service-section__dog-image {
      display: none !important;
    }

    .service-section__content {
      width: 600px;
      max-width: 600px;
      margin: 0 auto;
      float: none;
    }
  }

  @media screen and (max-width: 680px) {
    .service-section__content {
      width: 100%;
      max-width: 100%;
    }
  }
`;

const ExperienceSection = Styled.section`
  padding: 30px 0;

  h2 {
    text-align: center;
    margin-bottom: 45px;
  }
  `;

const CalloutQuoteSection = Styled.section`
  margin-top: 60px;
  margin-bottom: 60px;
  background-color: #2A67B6;
  padding: 24px;
  color: white;
  box-sizing: border-box;

  ${fullWidthMixin};

  blockquote {
    border-left: 5px solid black;
    border-color: #EEEEEE;
    padding-left: 20px;
    width: 700px;
    margin: 20px auto;

    p {
      font-size: 18px;
    }

    footer {
      font-style: italic;
      font-size: 14px;

      &:before {
        content: '\\2014 ';
      }
    }
  }

  @media screen and (max-width: 740px) {
    blockquote {
      width: 90%;
    }
  }
`;

const PricingSection = Styled.section`
  h2 {
    text-align: center;
  }

  .pricing-card {
    box-sizing: border-box;
    max-width: 100%;
    
    h4 {
      font-size: 30px;
      margin-top: 10px;
      margin-bottom: 18px;
    }

    hr {
      border: 1px solid #F8F8F8;
      margin: 12px 0;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
    }

    li {
      margin-bottom: 20px;
      font-size: 20px;
    }
  }
`;

const TestimonalSection = Styled.section`
  ${fullWidthMixin};

  h2 {
    text-align: center;
  }

  .testimonial-section__testimonials {
    display: flex;
    overflow-x: auto;
  }

  .testimonial-card {
    width: 35%;
    min-width: 35%;
  }

  @media screen and (max-width: 960px) {
    .testimonial-card {
      width: 52%;
      min-width: 52%;
    }
  }

  @media screen and (max-width: 720px) {
    .testimonial-card {
      width: 70%;
      min-width: 70%;
    }
  }
`;

const OurProcessSection = Styled.section`
margin-top: 60px;
margin-bottom: 60px;

h2 {
  text-align: center;
}

ul {
  list-style-image: url(${CheckMark});
}
`;

const OtherFactorsSection = Styled.section`
${fullWidthMixin};
padding-left: 24px;
padding-right: 24px;
box-sizing: border-box;

h2 {
  text-align: center;
}

h3 {
  text-align: center;
}

.inner-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
}

.gatsby-image-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.column {
  width: 30%;
}

@media screen and (max-width: 991px) {
  .inner-wrapper {
    flex-direction: column;
  }
  .column {
    width: 100%;
  }
}
`;

const BeforeAfterSection = Styled.section`
  ${fullWidthMixin};
  padding-left: 48px;
  padding-right: 48px;
  box-sizing: border-box;

  h2 {
    text-align: center;
  }

  .before-after-section__inner-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    max-width: 100%;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 776px) {
    .before-after-section__inner-wrapper {
      justify-content: center;
    }
  }

  @media screen and (max-width: 460px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const ContactUsSection = Styled.section`
h2 {
  text-align: center;
}

.contact-form {
  margin-top: 40px;
}
`;

export {
  Wrapper,
  CTASection,
  TrustBadgesSection,
  ServiceSection,
  ExperienceSection,
  CalloutQuoteSection,
  PricingSection,
  TestimonalSection,
  OurProcessSection,
  OtherFactorsSection,
  BeforeAfterSection,
  ContactUsSection,
};
